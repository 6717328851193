var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showContractModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModalAndUpdateContracts
    }
  }, [_c('ContractModal', {
    attrs: {
      "contractId": _vm.contractId,
      "userId": _vm.currentUserId,
      "courseId": _vm.currentCourseId,
      "headline": _vm.modalHeadline,
      "type": _vm.modalType
    },
    on: {
      "close": _vm.closeModalAndUpdateContracts,
      "refresh": _vm.refresh
    }
  })], 1) : _vm._e(), _vm.showRegisterHoursModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModalAndUpdateContracts
    }
  }, [_c('RegisterHoursModal', {
    attrs: {
      "name": _vm.getContact.customer.firstName + ' ' + _vm.getContact.customer.lastName,
      "courseId": _vm.currentCourseId,
      "userId": _vm.currentUserId
    },
    on: {
      "close": _vm.closeModalAndUpdateContracts,
      "refresh": _vm.refresh
    }
  })], 1) : _vm._e(), _vm.cancelModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.cancelModalData
    },
    on: {
      "submit": _vm.cancelSigning,
      "close": function close($event) {
        _vm.cancelModalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil avbryte signeringen av kontrakten? ")])], 1) : _vm._e(), _vm.resource.email ? _c('BaseLayout', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mr-4",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.showRegisterHoursModal = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Registrer timer ")], 1), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "newContract"
          },
          on: {
            "click": _vm.newContract
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Ny kontrakt ")], 1)];
      },
      proxy: true
    }], null, false, 2665902660)
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.getContact && _vm.getContact.customer ? _c('section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "data-cy": "personal-information-header",
      "title": "Personlig informasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.customer.firstName,
      "label": "Fornavn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.customer.lastName,
      "label": "Etternavn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.formatDate(_vm.getContact.customer.birthDate),
      "label": "Fødselsdato"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "label": "Kjønn",
      "value": _vm.getGender(_vm.getContact.customer.gender)
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Kontaktinformasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.email,
      "label": "E-post"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.mobileNumber,
      "label": "Telefonnummer"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Adresseinformasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.postAddress1,
      "label": "Adresse"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.postZip,
      "label": "Postnr"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.postCity,
      "label": "Poststed"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.getContact.postCountry,
      "label": "Land"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "divider"
  })], 1) : _vm._e(), _c('section', [_c('BaseTableSimple', {
    attrs: {
      "data-cy": "contract-table",
      "headers": _vm.headers,
      "items": _vm.contracts,
      "hidePaginator": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center align-self-center",
          attrs: {
            "data-cy": "contract-header"
          }
        }, [_vm._v(" Kontrakter ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [!_vm.contractIsEditable(item) ? _c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "view-contract"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewContract(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis kontrakt")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_vm.contractIsRegisteredNotSigned(item) && !_vm.contractAwaitsCancellationRequest(item) ? _c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click() {
                    return _vm.showCancelModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-close ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Avbryt signering")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_vm.contractIsEditable(item) ? _c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "edit-contract"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editContract(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger kontrakt")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [item.status === 'not_sent' ? _c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "edit-contract"
                },
                on: {
                  "click": function click($event) {
                    return _vm.signContract(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pen ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Send kontrakt for signering")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_vm.contractIsSigned(item) ? _c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.downloadContract(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-download ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Last ned kontrakt")])])];
      }
    }, {
      key: "item.inserted",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(_vm.formatBirthDate(value)) + " ")];
      }
    }, {
      key: "item.isSelfEmployed",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(value ? "Nei" : "Ja") + " ")];
      }
    }, {
      key: "item.contractLines",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [_vm._v(" " + _vm._s(_vm.getHoursSummary(value)) + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1)])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }