
import { ApiGetContractLineDtoType, ApiGetCostDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { loadingRef } from "@/shared/helpers/refHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import {
  validateBoolean,
  validateNonNegativeNumber,
  validateNotEmpty,
  validateMaxLength,
} from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import { api } from "@/api/api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ExpenseType } from "@/shared/enums/expenseType.enum";

const initialNewContractLine: () => ApiGetContractLineDtoType = () => ({
  id: 0,
  courseContractId: 0,
  costId: 0,
  costTypeName: "",
  inserted: "",
  insertedBy: "",
  updated: "",
  updatedBy: "",
  unit: "timer",
  amount: 0,
  price: 0,
  description: "",
  remainingAmount: 0,
  remainingApprovedAmount: 0,
  approvedWorkingHours: 0,
  workingHours: 0,
});

export default defineComponent({
  name: "ContractLines",
  components: { BaseTableSimple, BaseTooltipIconButton },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();

    const showCreateNewContractLine = ref(false);
    const contractLineBeingEdited = ref(false);
    const formIsValid = ref(false);
    const contractLineHeaderText = ref("");
    const isLoading = loadingRef(false);
    const costList = ref<ApiGetCostDto[]>([]);
    const singleCost = ref();

    const newContractLine = ref(initialNewContractLine());

    const readonly = computed(() => props.type === ModalType.Display);

    const contractLinesEditable = computed(
      () =>
        props.type !== ModalType.Display ||
        props.contract.status !== "signed" ||
        props.contract.status !== "sent_but_not_signed"
    );

    const getCostName = (value: number) => costList.value?.find((x) => x.id === value)?.name ?? "";

    const setCost = async (cost: any) => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        singleCost.value = (await api.economy.getCostAsync(cost)).data;
        newContractLine.value.price = singleCost.value.price;
        newContractLine.value.description = singleCost.value.description;
      });
    };

    const fetchCosts = async () => {
      globalLoadingWrapper({ blocking: true, type: LoadingType.Spinner }, async () => {
        const salaryCosts = (
          await api.economy.getCostsPerOrganizationAsync({ CostTypeCostGroup: ExpenseType.SalaryCosts })
        ).data;
        costList.value = salaryCosts.filter((cost) => cost.isActive === true);
      });
    };

    const cancelAddContractLine = () => {
      newContractLine.value = initialNewContractLine();
      showCreateNewContractLine.value = false;
    };

    const addContractLine = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return;
      }
      const newContractLineId = props.contract.contractLines?.length ?? 0;
      newContractLine.value.id = newContractLineId;
      // TODO fix
      // eslint-disable-next-line vue/no-mutating-props
      props.contract.contractLines?.push(newContractLine.value);
      newContractLine.value = initialNewContractLine();
      showCreateNewContractLine.value = false;
      openNotification(store, NotificationItemType.Success, "Kontraktlinjen lagt til.");
    };

    const viewContractLine = (item: ApiGetContractLineDtoType) => {
      newContractLine.value = { ...newContractLine.value, ...item };
      contractLineBeingEdited.value = true;
      showCreateNewContractLine.value = true;
      contractLineHeaderText.value = "Kontraktslinje";
    };

    const editContractLine = (item: ApiGetContractLineDtoType) => {
      newContractLine.value = { ...newContractLine.value, ...item };
      contractLineBeingEdited.value = true;
      showCreateNewContractLine.value = true;
      contractLineHeaderText.value = "Rediger kontraktslinje";
    };

    const updateContractLine = (item: ApiGetContractLineDtoType) => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid && !props.contract.contractLines) {
        return;
      }
      try {
        const idx = props.contract.contractLines.findIndex((el: ApiGetContractLineDtoType) => el.id === item.id);
        // TODO fix
        // eslint-disable-next-line vue/no-mutating-props
        props.contract.contractLines[idx] = item;
      } catch {
        openNotification(
          store,
          NotificationItemType.Error,
          "En feil oppsto i forsøket på å legge til kontraktslinjen."
        );
      }
      newContractLine.value = initialNewContractLine();
      showCreateNewContractLine.value = false;
    };

    const createContractLine = () => {
      contractLineBeingEdited.value = false;
      showCreateNewContractLine.value = true;
      contractLineHeaderText.value = "Ny kontraktlinje";
    };

    onMounted(async () => {
      isLoading.value = true;
      await fetchCosts();
      isLoading.value = false;
    });

    return {
      showCreateNewContractLine,
      contractLineBeingEdited,
      newContractLine,
      readonly,
      costList,
      singleCost,
      setCost,
      addContractLine,
      cancelAddContractLine,
      createContractLine,
      viewContractLine,
      editContractLine,
      updateContractLine,
      getCostName,
      validateMaxLength,
      validateNotEmpty,
      validateBoolean,
      validateNonNegativeNumber,
      headers,
      contractLinesEditable,
      formIsValid,
      contractLineHeaderText,
      isLoading,
    };
  },
});
const headers = [
  {
    text: "Kosttype",
    value: "costId",
  },
  {
    text: "Pris",
    value: "price",
  },
  {
    text: "Antall",
    value: "amount",
  },
  { text: "Handlinger", value: "actions", sortable: false },
];
