var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.modalConfig.headline,
      "modalType": _vm.modalConfig.type
    },
    on: {
      "submit": _vm.onSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.data.amount ? _c('v-text-field', {
    attrs: {
      "label": "Timer",
      "type": "number",
      "max": "1000",
      "min": "1",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.formValues.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "amount", $$v);
      },
      expression: "formValues.amount"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Beskrivelse"
    },
    model: {
      value: _vm.formValues.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "description", $$v);
      },
      expression: "formValues.description"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }