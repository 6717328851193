
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import {
  ApiGetContractLineDtoType,
  ApiGetContractWithFileDto,
  ApiGetEmployeeContractLineDtoType,
  ApiGetEmployeeWorkingHourDto,
  ApiGetWorkingHourDto,
} from "@/api/generated/Api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { api } from "@/api/api";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { CostType } from "@/shared/enums/costType.enum";

interface ApiCreateWorkingHour {
  amount: number | undefined;
  comment: string | null | undefined;
  date: string | undefined;
  status: string | null | undefined;
  courseContractId?: number | null | undefined;
  contractLineId?: number | undefined;
  isCancelled: boolean | undefined;
  isSickLeave: boolean | undefined;
}

export default defineComponent({
  name: "RegisterHoursModal",
  components: { BaseModalForm, BaseDatePicker },
  emits: ["refresh", "close"],
  props: {
    name: {
      type: String,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isExternalResource: {
      type: Boolean,
      required: false,
      default: true,
    },
    editValues: {
      type: Object as PropType<ApiGetWorkingHourDto | ApiGetEmployeeWorkingHourDto>,
      required: false,
    },
  },
  setup({ name, courseId, userId, isExternalResource, editValues }, context) {
    const contracts = ref<ApiGetContractWithFileDto[]>([]);
    const contractLines = ref<ApiGetContractLineDtoType[] | ApiGetEmployeeContractLineDtoType[]>([]);

    const selectedContract = computed(() =>
      [...contracts.value].find(
        (contract: ApiGetContractWithFileDto) => contract?.id === formValues.value.courseContractId
      )
    );

    const filteredContractLines = computed(() =>
      // A employee dont have a contract
      [...contractLines.value].filter((line: ApiGetContractLineDtoType | ApiGetEmployeeContractLineDtoType) =>
        "courseContractId" in line ? line.courseContractId === formValues.value.courseContractId : line
      )
    );

    const selectedContractLine = computed(() => {
      const returnValue = [...filteredContractLines.value].find(
        (line: ApiGetContractLineDtoType | ApiGetEmployeeContractLineDtoType) =>
          line.id === formValues.value.contractLineId
      );
      if (returnValue !== undefined && returnValue.scheduleWorkingHours) {
        returnValue.scheduleWorkingHours = returnValue.scheduleWorkingHours.map((scheduledWorkingHour) => ({
          ...scheduledWorkingHour,
          disabled:
            scheduledWorkingHour.scheduledWorkingHours - scheduledWorkingHour.totalRegisteredWorkingHours === 0
              ? true
              : false,
        }));
      }
      return returnValue;
    });

    const isEditing: boolean = editValues ? true : false;

    const formValues = ref<ApiCreateWorkingHour>({
      amount: 0,
      comment: "",
      date: "",
      courseContractId: 0,
      contractLineId: 0,
      status: "",
      isCancelled: false,
      isSickLeave: false,
    });

    const modalConfig = ref({
      headline: `${editValues ? "Oppdater" : "Legg til"} arbeidstimer for: ${name}`,
      type: ModalType.Edit,
      submitButtonText: editValues ? "Oppdater" : "Lagre",
    });

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([getContracts(), getContractLines()]);
        if (editValues) {
          const { amount, comment, date, status, isSickLeave, isCancelled } = editValues;

          formValues.value = {
            amount,
            comment,
            date,
            status,
            isSickLeave: isSickLeave || formValues.value.isSickLeave,
            isCancelled: isCancelled || formValues.value.isCancelled,
            courseContractId: "courseContractId" in editValues ? editValues.courseContractId : 0,
            contractLineId:
              "courseContractLineId" in editValues
                ? editValues.courseContractLineId
                : editValues.employeeContractLineId,
          };
        }
      });
    });

    const onSubmit = async () => {
      if (getValidatableRef(context.refs.form)?.validate()) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          if (isEditing) {
            await updateWorkingHours();
          } else {
            await createWorkingHours();
          }
          context.emit("close");
          context.emit("refresh");
        });
      }
      if (!contractLines.value.length) {
        context.emit("close");
      }
    };

    // Temporary fix, as these statuses will be added to the contractDTO in the future
    const signingFileStatusData = new Map([
      ["sending_to_middleware", { isStatusChangable: false, isDeletable: true, isSignable: false }],
      ["failed_sending_to_middleware", { isStatusChangable: false, isDeletable: true, isSignable: false }],
      ["received_by_middleware", { isStatusChangable: false, isDeletable: false, isSignable: false }],
      ["sending_to_signicat", { isStatusChangable: false, isDeletable: true, isSignable: false }],
      ["failed_sending_to_signicat", { isStatusChangable: false, isDeletable: true, isSignable: false }],
      ["sent_to_signicat", { isStatusChangable: false, isDeletable: false, isSignable: false }],
      ["registered_not_signed", { isStatusChangable: true, isDeletable: false, isSignable: false }],
      ["signed", { isStatusChangable: false, isDeletable: false, isSignable: true }],
      ["downloaded", { isStatusChangable: false, isDeletable: false, isSignable: true }],
      ["download_failed", { isStatusChangable: false, isDeletable: true, isSignable: true }],
      ["expired", { isStatusChangable: false, isDeletable: true, isSignable: false }],
    ]);

    const getContracts = async () => {
      const response = (await api.economy.getContractsForCourseAndUserAsync(courseId, userId)).data;
      contracts.value = response.map((contract) => ({
        ...contract,
        disabled: !signingFileStatusData.get(contract.status || "")?.isSignable,
      }));
    };

    const getContractLines = async () => {
      contractLines.value = (
        await api.course[isExternalResource ? "getCourseContractLines" : "getEmployeeContractLines"](courseId, userId)
      ).data;
    };

    const scheduledWorkingHourText = (scheduledWorkingHour: any) =>
      `${formatLocalizedDate(scheduledWorkingHour.start)}, ${scheduledWorkingHour.scheduledWorkingHours} timer (${
        scheduledWorkingHour.scheduledWorkingHours - scheduledWorkingHour.totalRegisteredWorkingHours === 0
          ? `førte timer: ${scheduledWorkingHour.totalRegisteredWorkingHours}`
          : `mangler ${Math.abs(
              scheduledWorkingHour.scheduledWorkingHours - scheduledWorkingHour.totalRegisteredWorkingHours
            )} timer`
      })`;

    const createWorkingHours = async () => {
      const { amount, comment, date, isSickLeave: isSickLeave, isCancelled } = formValues.value;

      await api.economy[isExternalResource ? "createWorkingHourAdminAsync" : "createEmployeeWorkingHourAsync"]({
        amount: amount || 0,
        comment: comment || "",
        date: date || "",
        courseContractLineId: formValues.value.contractLineId || 0,
        employeeContractLineId: formValues.value.contractLineId || 0,
        isSickLeave: isSickLeave || false,
        isCancelled: isCancelled || false,
      });
    };

    const updateWorkingHours = async () => {
      if (editValues) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          await api.economy[isExternalResource ? "updateWorkingHourAdminAsync" : "updateEmployeeWorkingHourAsync"](
            editValues.id,
            {
              amount: formValues.value.amount || 0,
              comment: formValues.value.comment || "",
              date: formValues.value.date || "",
              courseContractLineId: formValues.value.contractLineId || 0,
              employeeContractLineId: formValues.value.contractLineId || 0,
              isSickLeave: formValues.value.isSickLeave,
              isCancelled: formValues.value.isCancelled,
            }
          );
        });
      }
    };

    const getContractItemText = (item: ApiGetContractWithFileDto) =>
      `${item.id} - ${item.roleName} - kontrakten er ${item.statusText}`;

    const getContractLineItemText = (item: ApiGetContractLineDtoType) =>
      `${item.id} - ${item.costTypeName} - ${item.description}`;

    return {
      modalConfig,
      contractLines,
      filteredContractLines,
      contracts,
      formValues,
      selectedContractLine,
      selectedContract,
      onSubmit,
      createWorkingHours,
      scheduledWorkingHourText,
      validateNotEmpty,
      CostType,
      getContractItemText,
      getContractLineItemText,
    };
  },
});
