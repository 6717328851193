var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.modalConfig.headline,
      "modalType": _vm.modalConfig.type,
      "submitButtonText": _vm.modalConfig.submitButtonText,
      "editable": ""
    },
    on: {
      "submit": _vm.onSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm.contractLines.length ? [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.isExternalResource ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.contracts,
      "rules": _vm.validateNotEmpty,
      "item-text": _vm.getContractItemText,
      "item-value": "id",
      "label": "Velg kontrakt"
    },
    model: {
      value: _vm.formValues.courseContractId,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "courseContractId", $$v);
      },
      expression: "formValues.courseContractId"
    }
  }) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "items": _vm.filteredContractLines,
      "rules": _vm.validateNotEmpty,
      "item-text": _vm.getContractLineItemText,
      "item-value": "id",
      "label": "Velg kontrakts linje"
    },
    model: {
      value: _vm.formValues.contractLineId,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "contractLineId", $$v);
      },
      expression: "formValues.contractLineId"
    }
  }), _vm.selectedContractLine && _vm.selectedContractLine.costTypeName !== _vm.CostType.TeachingHours ? _c('BaseDatePicker', {
    attrs: {
      "label": "Dato",
      "readonly": false,
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.formValues.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "date", $$v);
      },
      expression: "formValues.date"
    }
  }) : _vm._e(), _vm.selectedContractLine && _vm.selectedContractLine.costTypeName === _vm.CostType.TeachingHours ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.selectedContractLine.scheduleWorkingHours,
      "rules": _vm.validateNotEmpty,
      "item-text": _vm.scheduledWorkingHourText,
      "disabled": _vm.editValues ? true : false,
      "label": "Velg dag",
      "item-value": "start"
    },
    model: {
      value: _vm.formValues.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "date", $$v);
      },
      expression: "formValues.date"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Antall timer"
    },
    model: {
      value: _vm.formValues.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "amount", $$v);
      },
      expression: "formValues.amount"
    }
  }), _vm.selectedContractLine ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_vm.selectedContractLine.costTypeName === 'Undervisningstimer' ? _c('v-switch', {
    attrs: {
      "label": "Syk"
    },
    model: {
      value: _vm.formValues.isSickLeave,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "isSickLeave", $$v);
      },
      expression: "formValues.isSickLeave"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_vm.selectedContractLine.costTypeName === 'Undervisningstimer' ? _c('v-switch', {
    attrs: {
      "label": "Avlyst"
    },
    model: {
      value: _vm.formValues.isCancelled,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "isCancelled", $$v);
      },
      expression: "formValues.isCancelled"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('v-textarea', {
    attrs: {
      "label": "Kommentar",
      "filled": ""
    },
    model: {
      value: _vm.formValues.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "comment", $$v);
      },
      expression: "formValues.comment"
    }
  })], 1)] : [_vm._v(_vm._s(_vm.name) + " mangler en kontrakt eller en linje på kontrakten sin ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }