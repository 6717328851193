
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { computed, defineComponent, onMounted, PropType, Ref, ref } from "@vue/composition-api";
import {
  ApiGetCostTypeDto,
  ApiGetEmployeeContractLineDtoType,
  ApiUpsertEmployeeContractLineDto,
} from "@/api/generated/Api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { api } from "@/api/api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";

export default defineComponent({
  name: "EditHoursModal",
  components: { BaseModalForm },
  emits: ["refresh"],
  props: {
    data: {
      type: Object as PropType<ApiGetEmployeeContractLineDtoType>,
      required: true,
    },
  },
  setup({ data }, context) {
    const costTypes = ref<ApiGetCostTypeDto[]>([]);
    const modalConfig = {
      headline: "Endre undervisningstimer",
      type: ModalType.Edit,
    };

    const costTypeId = computed<number>(() => {
      const costType = costTypes.value.find((costType) => costType.name === data.costTypeName);
      return costType ? costType.id : 0;
    });

    const formValues: Ref<ApiUpsertEmployeeContractLineDto> = ref({
      description: data.description,
      amount: data.amount,
      costTypeId,
    });

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await getCostTypes();
      });
    });

    const onSubmit = async () => {
      if (getValidatableRef(context.refs.form)?.validate()) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          await api.course.updateEmployeeContractLine(data.id, formValues.value);
          context.emit("updated");
        });
      }
    };

    const getCostTypes = async () => {
      costTypes.value = (await api.economy.getCostTypesAsync()).data;
    };

    return { onSubmit, formValues, modalConfig, validateNotEmpty };
  },
});
