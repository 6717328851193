
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import { ApiGetCurrentEmployeeDto, ApiGetEmployeeContractLineDtoType } from "@/api/generated/Api";
import { api } from "@/api/api";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import BaseModal from "@/components/shared/BaseModal.vue";
import EditEmployeeHoursModal from "@/components/course/resources/EditEmployeeHoursModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import RegisterHoursModal from "@/components/course/resources/RegisterHoursModal.vue";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { StoreState } from "@/store/store.state.interface";

export default defineComponent({
  name: "ViewEmployee",
  components: {
    BaseLayout,
    BaseSheetHeader,
    BaseSheetField,
    BaseTableSimple,
    BaseModal,
    EditEmployeeHoursModal,
    RegisterHoursModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();

    const userId: ComputedRef<number> = computed(() => parseInt(route.params.resourceId));
    const courseId: ComputedRef<number> = computed(() => parseInt(route.params.id));
    const showEditHoursModal: Ref<boolean> = ref(false);
    const selectedTableRow: Ref<ApiGetEmployeeContractLineDtoType> = ref(getInitialEmployeContractLine());

    const showAddHoursModal: Ref<boolean> = ref(false);

    const employee: Ref<ApiGetCurrentEmployeeDto | null> = ref(null);
    const employeeContractLines: Ref<ApiGetEmployeeContractLineDtoType[] | null> = ref(null);

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        employee.value = (await api.user.getEmployeeAsync(userId.value)).data;
        await getSetEmployeeContractLines();
      });
    });

    const openEditHoursModal = (item: ApiGetEmployeeContractLineDtoType) => {
      selectedTableRow.value = item;
      showEditHoursModal.value = true;
    };

    const openAddHoursModal = () => {
      showAddHoursModal.value = true;
    };

    const getSetEmployeeContractLines = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        employeeContractLines.value = (await api.course.getEmployeeContractLines(courseId.value, userId.value)).data;
      });
    };

    const closeEditHoursModal = () => (showEditHoursModal.value = false);
    const closeAddHoursModal = () => (showAddHoursModal.value = false);

    const refresh = () => {
      closeEditHoursModal();
      closeAddHoursModal();
      getSetEmployeeContractLines();
    };

    const headers = [
      {
        text: "Timer",
        value: "amount",
      },
      {
        text: "Beskrivelse",
        value: "description",
      },
      {
        text: "Kosttype",
        value: "costTypeName",
      },
      {
        text: "KostId",
        value: "costId",
      },
      {
        text: "Handlinger",
        value: "actions",
      },
    ];
    return {
      course: computed(() => store.state.courses.course),
      employee,
      employeeContractLines,
      headers,
      selectedTableRow,
      showEditHoursModal,
      showAddHoursModal,
      courseId,
      userId,
      router,
      refresh,
      openEditHoursModal,
      openAddHoursModal,
      closeAddHoursModal,
      closeEditHoursModal,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseResources),
    };
  },
});

const getInitialEmployeContractLine = (): ApiGetEmployeeContractLineDtoType => ({
  amount: 0,
  approvedWorkingHours: 0,
  costId: 0,
  id: 0,
  inserted: "",
  remainingAmount: 0,
  remainingApprovedAmount: 0,
  updated: "",
  workingHours: 0,
});
