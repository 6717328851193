var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.formIsValid,
      callback: function callback($$v) {
        _vm.formIsValid = $$v;
      },
      expression: "formIsValid"
    }
  }, [!_vm.showCreateNewContractLine ? _c('section', [_c('BaseTableSimple', {
    attrs: {
      "data-cy": "contract-lines-table",
      "headers": _vm.headers,
      "items": _vm.contract.contractLines,
      "hidePaginator": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          attrs: {
            "data-cy": "contract-lines-header"
          }
        }, [_vm._v("Kontraktslinjer")]), _c('v-spacer'), !_vm.readonly ? _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Legg til kontraktlinje",
            "icon": "mdi-plus",
            "data-cy": "createContractLine"
          },
          on: {
            "handleClick": _vm.createContractLine
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "view-contract-line"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewContractLine(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis kontraktlinje")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [!_vm.readonly ? _c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.editContractLine(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")]) : _vm._e()];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger kontraktlinje")])])];
      }
    }, {
      key: "item.costId",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.getCostName(value)) + " ")];
      }
    }, {
      key: "item.price",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.amount",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1) : _vm._e(), _vm.showCreateNewContractLine ? _c('section', [_c('div', {
    staticClass: "title font-weight-light"
  }, [_vm._v(_vm._s(_vm.contractLineHeaderText))]), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "items": _vm.costList,
      "label": "Kosttype",
      "item-text": function itemText(item) {
        return item.name;
      },
      "item-value": "id",
      "rules": [function (v) {
        return !!v || 'Velg kostnadstype.';
      }]
    },
    on: {
      "change": function change($event) {
        return _vm.setCost($event);
      }
    },
    model: {
      value: _vm.newContractLine.costId,
      callback: function callback($$v) {
        _vm.$set(_vm.newContractLine, "costId", $$v);
      },
      expression: "newContractLine.costId"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "data-cy": "price",
      "label": "Pris",
      "rules": [].concat(_vm.validateNotEmpty, _vm.validateNonNegativeNumber)
    },
    model: {
      value: _vm.newContractLine.price,
      callback: function callback($$v) {
        _vm.$set(_vm.newContractLine, "price", _vm._n($$v));
      },
      expression: "newContractLine.price"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "data-cy": "amount",
      "label": "Antall",
      "rules": [].concat(_vm.validateNotEmpty, _vm.validateNonNegativeNumber)
    },
    model: {
      value: _vm.newContractLine.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.newContractLine, "amount", _vm._n($$v));
      },
      expression: "newContractLine.amount"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "description",
      "label": "Beskrivelse",
      "readonly": _vm.readonly,
      "counter": "50",
      "rules": [_vm.validateMaxLength()].concat(_vm.validateNotEmpty)
    },
    model: {
      value: _vm.newContractLine.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newContractLine, "description", $$v);
      },
      expression: "newContractLine.description"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "contractline-actions-container"
  }, [!_vm.contractLineBeingEdited && !_vm.readonly ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "fab": "",
      "x-small": "",
      "data-cy": "addContractLine"
    },
    on: {
      "click": _vm.addContractLine
    }
  }, [_c('v-icon', [_vm._v(" mdi-plus ")])], 1) : _vm._e(), _vm.contractLineBeingEdited && !_vm.readonly ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "fab": "",
      "x-small": "",
      "disabled": !_vm.formIsValid
    },
    on: {
      "click": function click($event) {
        return _vm.updateContractLine(_vm.newContractLine);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-check")])], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "fab": "",
      "x-small": ""
    },
    on: {
      "click": _vm.cancelAddContractLine
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1)])], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }