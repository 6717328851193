
import { api, binaryApi } from "@/api/api";
import { ApiGetContractLineDtoType, ApiGetContractWithFileDto } from "@/api/generated/Api";
import ContractModal from "@/components/course/contract/ContractModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import fileDownload from "js-file-download";
import { getStatusText } from "@/shared/helpers/resourceHelpers";
import { isValidStringProperty } from "@/shared/helpers/typeHelpers";
import { genderMap } from "@/components/course/details/participant/ParticipantGeneralInfo.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import RegisterHoursModal from "@/components/course/resources/RegisterHoursModal.vue";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

export default defineComponent({
  name: "ViewCustomer",
  components: {
    RegisterHoursModal,
    BaseLayout,
    BaseConfirmModalForm,
    BaseSheetHeader,
    BaseSheetField,
    BaseModal,
    BaseTableSimple,
    ContractModal,
  },
  setup(_, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const showContractModal = ref(false);
    const showRegisterHoursModal = ref(false);
    const modalHeadline = ref("");
    const modalType = ref("");
    const currentContractId = ref(0);
    const cancelModalData = ref(getInitialModalData());

    const showCancelModal = useOpenModal(ModalType.Unspecified, "", cancelModalData);

    const currentUserId = computed(() => +route.params.resourceId);
    const currentCourseId = computed(() => +route.params.id);
    const contractId = computed(() => currentContractId.value);
    const resource = computed(() => store.state.contacts.contactSingle);
    const contracts = computed<ApiGetContractWithFileDto[]>(() => store.state.contracts.contracts);
    const getContact = computed(() => store.state.contacts.contactSingle);

    const contractIsRegisteredNotSigned = (item: ApiGetContractWithFileDto) => item.status === "registered_not_signed";
    const contractAwaitsCancellationRequest = (item: ApiGetContractWithFileDto) =>
      !!item.contractRequests?.find((r) => r.type === "CANCELLATION" && r.status === "SENT");
    const contractIsSigned = (item: ApiGetContractWithFileDto) =>
      item.status === "signed" && item.contractFile?.signedFileId;
    const viewContract = (item: ApiGetContractWithFileDto) => {
      currentContractId.value = item.id;
      modalHeadline.value = "Vis kontrakt";
      modalType.value = ModalType.Display;
      showContractModal.value = true;
    };

    const editContract = (item: ApiGetContractWithFileDto) => {
      currentContractId.value = item.id;
      modalHeadline.value = "Rediger kontrakt";
      modalType.value = ModalType.Edit;
      showContractModal.value = true;
    };

    const newContract = () => {
      currentContractId.value = 0;
      modalHeadline.value = "Opprett ny kontrakt";
      modalType.value = ModalType.Add;
      showContractModal.value = true;
    };
    const cancelSigning = async () => {
      const id = cancelModalData.value.existingItem.id;

      globalLoadingWrapper({ blocking: true }, async () => {
        if (!id) {
          openNotification(store, NotificationItemType.Error, "Dokument mangler ID");
          cancelModalData.value.showModal = false;
          return;
        }

        try {
          await binaryApi.economy.sendContractCancellationRequestAsync(id);
          openNotification(
            store,
            NotificationItemType.Success,
            "Kansellering fullført. Det kan ta en stund før oppdateringen vises i tabellen."
          );
          fetchContracts();
          cancelModalData.value.showModal = false;
        } catch (error: any) {
          if (error.response && error.response.data && error.response.data.errors) {
            error.response.data.errors.forEach((e: any): void => {
              openNotification(store, NotificationItemType.Error, e.errorMsg);
            });
          }
        }
      });
    };

    const getGender = (genderString: string) => {
      const gender = genderString?.toLowerCase();
      if (isValidStringProperty(genderMap, gender)) {
        return genderMap[gender];
      }
      return "Ukjent";
    };

    const contractIsEditable = (item: ApiGetContractWithFileDto) => item.status === "not_sent";
    // Iterates thorugh all contractLines, returning sum by price.
    const getHoursSummary = (item: ApiGetContractLineDtoType[]) =>
      item.reduce((sum: number, { amount }: ApiGetContractLineDtoType) => sum + (amount || 0), 0);
    const formatBirthDate = (birthDate: string) => formatDate(birthDate);
    const downloadContract = async (item: ApiGetContractWithFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!item.contractFile || !item.contractFile.signedFileId) {
          return;
        }
        const contractFile = await binaryApi.file.downloadFileAsync(item.contractFile.signedFileId, { format: "blob" });
        const generatedFilename = `${resource.value.customer.lastName}_${resource.value.customer.firstName}_contractid${item.id}.pdf`;
        fileDownload(contractFile.data, generatedFilename);
      });
    };

    const closeModalAndUpdateContracts = async () => {
      await setPerson();
      await fetchContracts();
      showContractModal.value = false;
      showRegisterHoursModal.value = false;
    };
    const setPerson = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await runStoreAction(
          store,
          StoreModules.Contacts,
          StoreActions.ContactsActions.FetchPerson,
          currentUserId.value
        );
      });
    };
    const fetchContracts = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const payload = {
          courseId: currentCourseId.value,
          userId: currentUserId.value,
        };

        await runStoreAction(store, StoreModules.Contracts, StoreActions.ContractActions.FetchContracts, payload);
      });
    };

    const refresh = () => {
      fetchContracts();
    };

    onMounted(async () => {
      await setPerson();
      await fetchContracts();
    });

    const signContract = async (item: any) => {
      if (!item.id) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.economy.sendContractForSigningAsync(item.id);
          openNotification(store, NotificationItemType.Success, "Kontrakt sendt for signering");
        } catch (error) {
          openNotification(
            store,
            NotificationItemType.Error,
            "En feil oppsto ved innsending av kontrakt for signering"
          );
          return;
        } finally {
          emit("close");
          await fetchContracts();
        }
      });
    };

    return {
      course: computed(() => store.state.courses.course),
      formatDate,
      cancelModalData,
      showContractModal,
      showRegisterHoursModal,
      modalHeadline,
      modalType,
      currentUserId,
      currentCourseId,
      resource,
      contracts,
      refresh,
      viewContract,
      editContract,
      newContract,
      fetchContracts,
      showCancelModal,
      cancelSigning,
      headers,
      closeModalAndUpdateContracts,
      contractIsEditable,
      getHoursSummary,
      formatBirthDate,
      downloadContract,
      getContact,
      contractId,
      contractIsSigned,
      contractIsRegisteredNotSigned,
      contractAwaitsCancellationRequest,
      getStatusText,
      getGender,
      router,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseResources),
      signContract,
    };
  },
});
const headers = [
  {
    text: "Status",
    value: "statusText",
  },
  { text: "Handlinger", value: "actions", sortable: false },
  {
    text: "Opprettet",
    value: "inserted",
  },
  {
    text: "Lønnsmottaker?",
    value: "isSelfEmployed",
  },
  {
    text: "Sum timer",
    value: "contractLines",
  },
];
