var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showEditHoursModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeEditHoursModal
    }
  }, [_c('EditEmployeeHoursModal', {
    attrs: {
      "data": _vm.selectedTableRow
    },
    on: {
      "close": _vm.closeEditHoursModal,
      "updated": _vm.refresh
    }
  })], 1) : _vm._e(), _vm.showAddHoursModal && _vm.employee ? _c('BaseModal', {
    on: {
      "close": _vm.closeAddHoursModal
    }
  }, [_c('RegisterHoursModal', {
    attrs: {
      "name": _vm.employee.firstName + ' ' + _vm.employee.lastName,
      "courseId": _vm.courseId,
      "userId": _vm.userId,
      "orgId": _vm.employee.organizationId,
      "isExternalResource": false
    },
    on: {
      "close": _vm.closeAddHoursModal,
      "refresh": _vm.refresh
    }
  })], 1) : _vm._e(), _vm.employee ? _c('BaseLayout', {
    attrs: {
      "outlined": false,
      "rounded": "lg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mr-4",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.openAddHoursModal
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Registrer timer ")], 1)];
      },
      proxy: true
    }], null, false, 4003128870)
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('BaseSheetHeader', {
    attrs: {
      "data-cy": "personal-information-header",
      "title": "Personlig informasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.employee.firstName,
      "label": "Fornavn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.employee.lastName,
      "label": "Etternavn"
    }
  })], 1)], 1), _c('BaseSheetHeader', {
    attrs: {
      "title": "Kontaktinformasjon"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.employee.currentOrganizationName,
      "label": "Organisasjon"
    }
  })], 1)], 1)], 1), _vm.employeeContractLines ? _c('section', [_c('v-spacer'), _c('BaseSheetHeader', {
    attrs: {
      "title": "Undervisningstimer"
    }
  }), _c('BaseTableSimple', {
    attrs: {
      "data-cy": "contract-table",
      "headers": _vm.headers,
      "items": _vm.employeeContractLines,
      "hidePaginator": true
    },
    scopedSlots: _vm._u([{
      key: "item.amountOfHours",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value))];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value))];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-icon', {
          attrs: {
            "data-cy": "edit-hours"
          },
          on: {
            "click": function click($event) {
              return _vm.openEditHoursModal(item);
            }
          }
        }, [_vm._v(" mdi-pencil")])];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1) : _vm._e()])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }